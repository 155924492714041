.allotment-card {
    border: 2px solid #E2E6EA;
    border-radius: 8px;
    min-height: 310px;
    padding: 2rem .5rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: .5rem;
    margin-bottom: 20px;
    position: relative;
    &.selected {
        border-color: #FFBD2E;
    }
    h3 {
        font-size: 16px;
        font-weight: bold;
    }
    
   .middle {
    width: 100%;
    padding: 2rem .5rem;
    .features {
        color: #5A6268;
        font-size: 12px;
        .name {
            font-weight: bold;
        }
        .value {
            text-align: right;
        }
    }
   }

   .discount {
        position: absolute;
        top: 2rem;
        right: 10px;
        height: 20px;
        min-width: 50px;
        border-radius: 40px;
        color: #FCFCFC;
        padding: 0 .5rem;
        font-size: 14px;
        &.danger {
            background-color: #F5755A;
        }
        &.red {
            background-color: #DC3545;
        }
        &.success {
            background-color: #28A745;
        }
        &.warning {
            background-color: #FFC107;
        }
        &.primary {
            background-color: #45BEB4;
        }
        &.secondary {
            background-color: #6C757D;
        }
   }
}