.messaging-card {
    display: flex;
    align-items: start;
    justify-content: space-between;
    .button-wrapper {
        .btn {
            margin-right: .5rem;
            border-radius: 20px;
        }
    }
    .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .text  {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
        p {
            -webkit-line-clamp: 1;
            text-wrap: wrap;
            text-overflow: ellipsis;
        }
    }
    .end {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .count {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #58AAFF;
        color: #fff;
        padding: .5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        &.success {
            background-color: #58AAFF;
        }
    }
    .time {
        font-size: 10px;
        color: #000000;
    }
}


.scheduling-card {
    .start {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;
        .title {
            color: #28A745;
            font-size: 12px;
            font-weight: bold;
        }
    }
    .content {
        .title {
            font-size: 12px;
            span {
                margin-left: .5rem;
            };
        }
    }
}
.user-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
}