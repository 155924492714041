.channel-hero {
    height: 280px;
    border-radius: 8px;
    background-position: center center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
  
    .channel-hero-content {
        max-width: 50%;
        .title-container {
            display: flex;
            align-items: center;
            justify-content: start;
            gap: 2rem;
            height: 32px;
            min-width: 80px;
            h1 {
                font-size: 40px;
                color: #23272B;
            }
            .btn {
                background-color: #3C3C3C;
                border-radius: 24px;
            }
        }
      
        p {
            font-size: 20px;
            color: #23272B;
        }

        span {
            color: #23272B;
        }
    }
}