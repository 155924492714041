.nav {
    border-bottom: 2px solid #1C333B29;
    .nav-link  {
        font-weight: bold;
        color: #3D3D3D;
        font-size: 16px;
    }
    .btn {
        border-radius: 24px;
    }
}