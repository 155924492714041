/*
* Utils
/*
* Tailwind setup
*/

@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'utils/vars.scss';

/*
* General Components
*/
@import 'components/button';
@import 'components/inputs';
@import 'components/nav';

/*
* Dashboard Components
*/
@import 'components/sidebar';
@import 'components/sidebar-widget';
@import 'components/bulletin';
@import 'components/messaging-card';


/*
* Channel Components
*/
@import 'components/channel-card';
@import 'components/channel-hero';
@import 'components/channel-stats';
@import 'components/email-tab';

/*
* Org Components
*/
@import 'components/org-channel-card';
@import 'components/user-allotment';

h1, h2, h3, h4, h5, h6, p {
    color: #5A6268;
}


.dashboard-wrapper {
    background-color: #F0F0F0;
    
    .channel-main {
        background-color: #E2E6EA;
        width: 100%;
    }
}