.email-tab {
    h3 {
        font-size: 20px;
        color: #5A6268;
        font-weight: bold;
    }
    table {
        td {
            color: #5A6268;
        }
    }
    i {
        cursor: pointer;
        font-size: 24px;
    }
}