.channel-card {
    border-radius: 25px;
    height: 300px;
    color: #F8F9FA;
    .card-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: inherit;
        .title {
            font-size: 20px;
        }
        p {
            color: #F8F9FA;
        }
        a {
            color: #F8F9FA;
            font-size: 12px;
        }
        .icon-text {
            span {
                background-color: transparent;
                border-radius: none;
                font-size: 12px;
                color: #F8F9FA;
                margin-right: 1rem;
            }
        }
    }
    

}