.icon-text {
    a {
        svg {
            margin-right: .5rem;
        }
    }
}

.avatar {
    display: flex;
    gap: .5rem;
    h6 {
        color: #5A6268;
        font-size: 14px;
        font-weight: bold;
    }
    span {
        color: #58AAFF !important;
        font-size: 14px;
    }
    img {
        width: 40px;
    }
}

body {
    font-family: Helvetica, sans-serif;
}