.org-channel-card {
    height: 100%;
    border-radius: 20px;
    .thumbnail {
        position: relative;
        button {
            cursor: pointer;
            position: absolute;
            top: 10px;
            right: 10px;
            color: #fff;
            font-size: 20px;
        }
        img {
            max-height: 200px;
            height: auto !important;
            width: 100%;
            object-fit: cover;
            aspect-ratio: 16/9;
        }
    }
    .dropdown {
        position: absolute;
        right: 0;
        top: 25px;
        background-color: #fff;
        padding: .5rem;
        li {
            display: flex;
            align-items: center;
            justify-content: start;
            gap: .3rem;
            margin-bottom: .5rem;
            &:hover {
                background-color: #E2E6EA;
            }
        }
        &.show {
            display: block !important;
            a {
                color: #000000;
                font-size: 10px;
                font-weight: bold;
                &:hover {
                    background-color: #E2E6EA;
                }
            }
        }
    }
    .content {
        background-color: #fff;
        font-size: 11px;
        border-radius: 0 0 8px 8px;
        .stats {
            font-size: 14px;
        }
    }
}


.channel-list {
    margin-top: 2rem;
    background-color: #E2E6EA;
    padding: 1rem 2rem;
    border-radius: 8px;
    position: relative;
    height: 50%;
}
.add-channel-btn {
    background-color: #58AAFF;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    position: absolute;
    right: 10px;
    bottom: 10px; 
    i {
        font-size: 24px;
            color: #fff;
    }
}

.channel-steps {
    display: flex;
    justify-content: start;
    .step {
        font-weight: bold;
        font-size: 12px;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        .number {
            width: 24px;
            height: 24px;
            border: 1px solid #B0B6BA;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            color: #B0B6BA !important;
            
        }
        i {
            font-size: 24px;
        }
        .text {
            color: #B0B6BA;
        }
        &.active {
            .number {
                background-color: #343A40;
                color: #fff;
                border: none !important;
            }
            .text {
                color: #343A40;
            }
        }
    }
}