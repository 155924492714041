.auth-form {
    h1 {
        color: $light-dark;
        font-size: 40px;
        text-align: center;
        font-weight: bold;
        margin-bottom: 20px;
    }
    input {
        border: 2px solid $grey;
        color: $light-dark;
        font-size: 14px;
        border-radius: 8px;
    }
    .btn {
        margin: 20px 0;
    }
    .text {
        margin-top: 20px;
        text-align: center;
        color: $light-dark;
    }
}

.channel-form {
    height: 100%;
    padding: 2rem 4rem;
    input, textarea, select {
        border: 2px solid $grey;
        color: $light-dark;
        font-size: 14px;
        border-radius: 8px;
        &:focus {
            border: 2px solid $grey;
            outline: none !important;
        }
    }
    .close-icon {
        cursor: pointer;
        background-color: #343A40;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        border-radius: 100%;
        i {
            font-size: 24px;
            color: #fff;
        }
    }

    .header {
        max-width: 80%;
    }

    .tab-btn {
        &.active {
            background-color: transparent;
            color: #7A7E83;
            border: 2px solid #E2E6EA;
        }
    }
}

