.bulletin-card {
    color: #23272B;
    .title {
        font-size: 12px;
        span {
            margin-left: .5rem;
        }
    }
    .stats {
        font-size: 14px;
        font-weight: bold;
    }
}