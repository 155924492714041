.sidebar-widget {
    .menu-items {
        border-radius: 40px;
        height: 55px;
        background-color: $light-2;
        display: flex;
        align-items: center;
        padding: 1rem;
        margin-bottom: 2rem;
       .menu-item {
            cursor: pointer;
            display: flex;
            flex-direction: column;
            align-items: center;
            span {
                font-size: 12px;
                color: #B0B6BA;
                font-weight: bold;
                i {
                    color: #B0B6BA;
                }
               
            }
            &:hover, &.active {
                i,span {
                    color:  $blue;
                }
            }
       }
    }

    .widget-card {
        background-color: #FFFFFF;
        border-radius: 4px;
        padding: 1rem;
        .title {
            font-size: 12px;
            span {
                margin-left: .5rem;
            }
        }
        p {
            font-size: 12px;
            margin: .5rem 0;
        }
        .stats {
            font-size: 14px;
            font-weight: bold;
        }
        .btn-sm {
            font-size: 8px;
            margin-right: .5rem;
        }
        .card-wrappwer {
            display: flex;
            align-items: start;
            justify-content: start;
            gap: .5rem;
            .avatar {
                width: 100px;
            }
        }
    }
}