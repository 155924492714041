.btn {
    min-width: 100px;
    text-align: center;
    border: none;
    &:focus, &:hover {
        box-shadow: none;
        outline: none;
        background-color: none;
        border: none;
        color: $light;
    }
    &.btn-orange {
        background-color: $orange;
    }
    &.btn-danger {
        background-color: $red;
    }
    &.btn-primary {
        background-color: $blue;
    }
    &.btn-dark {
        background-color: $dark;
        &:hover {
            background-color: $light;
            color: $dark;
            border: 2px solid #E2E6EA;
        }
    }
    &.btn-success {
        background-color: #28A745;
        color: #fff;
    }
    &.btn-sm {
        font-size: 12px;
    }

    &.btn-full {
        width: 100%;
    }

    &.btn-rounded {
        border-radius: 20px;
    }
}

// to be removed
.order-page {
    max-width: 350px;
    margin: 0 auto;
    .allotment-card {
        padding: 2rem;
    }
}