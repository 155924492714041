
.side-nav {
    .dashboard-sidebar  {
        background-color: #fff;
        overflow-y: hidden;
    }
    .sidebar-link  {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: .5rem;
        justify-content: center;
        // margin-bottom: 5px;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        font-weight: bold;
        font-family: Helvetica;
        .logo {
            width: 50px;
        }
        span {
            font-size: 12px;
            color: #343A40;
        }
        &:hover {
            svg {
                fill: #007BFF;
            }
            span {
                color: #007BFF;
            }
        }
    }
}